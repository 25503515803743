import React, {Component} from 'react'
import {  Form, Icon, Header, Statistic } from 'semantic-ui-react'
import {withRouter, Route} from 'react-router-dom'
import client from '../../shared/feathers'

class RegisterForm extends Component{

    render(){
        return (
            <Form style={{paddingTop:15}}>
                <Header as="h3">Registrieren</Header>
                <div style={{marginBottom:20}}>
                    <p>Wenn Sie noch kein Benutzerkonto besitzen, können Sie sich hier ein erstellen. Verwenden Sie dazu Ihren Sicherheitsschlüssel den Sie bekommen haben.</p>
                </div>
                
                <Form.Button onClick={()=>this.props.history.push("/Authentification/Register")} primary fluid icon labelPosition="right" style={{textAlign:"left"}}>
                    Jetzt Registrieren
                    <Icon name='arrow right' />
                </Form.Button>
            </Form>
        )
    }
} 

export default withRouter(RegisterForm)