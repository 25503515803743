import React,{ Component } from "react";
import { Segment, Menu, Input, Icon, Table, Pagination, Header, Select } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import CustomersNew from "./new";
import Currency from "react-currency-formatter";
import client from "../../shared/feathers";
let types = {commercial:"Unternehmen",private:"Privat"}
function list_to_tree(list) {
    var map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) { map[list[i].id] = i; list[i].children = []; }
    for (i = 0; i < list.length; i += 1) { node = list[i]; if (node.category_id ) { list[map[node.category_id]].children.push(node); } else { roots.push(node);}}
    return roots;
}
class Products  extends Component{

    state={
        query:{},
        data:{
            total:1,
            limit:1,
            data:[]
        },
        categories:[],
        catNames:{},
        page: 1,
        loading:true
    }
    componentDidMount(){
        document.title = 'Produkte';
        this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("products").watch().find({query: {$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
    }

    toTreeSelect(obj,padding=" "){
        return obj.flatMap((elm, id) => [
            {key: elm.id, text: padding+" "+elm.name+" ("+elm.id+")", value: elm.id},
            ...this.toTreeSelect(elm.children,padding+"--")
        ])
    }
    catToObj(obj){
        let result = {}
        for (let entry of obj){
            result[entry.id]=entry.name
        } return result
    }
    async syncAll(){
      this.setState({loading:true})
      let {data} = await client.service("products").find({query:{$limit:10000000000}})
      for(let entry of data){
        console.log(entry)
        await client.service("products").patch(entry.id,{debug:Math.floor(Math.random() * 1100)})
      }
      this.setState({loading:false})
    }
    render(){
        return ( 
            <Segment basic>
                <Menu>
                    <Menu.Item><b>Produkte</b></Menu.Item>
                    <Menu.Menu position="right">
                        <CustomersNew trigger={<Menu.Item  ><Icon name="plus square" /> Hinzufügen</Menu.Item>} />
                        {/* <Menu.Item onClick={this.syncAll.bind(this)} ><Icon name="refresh" /> Syncronisieren</Menu.Item> */}
                    </Menu.Menu>
                </Menu>
                <Segment loading={this.state.loading} style={{padding:0}}>
                    <Table selectable celled size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                <Table.HeaderCell>Kanal</Table.HeaderCell>
                                <Table.HeaderCell>Produkt</Table.HeaderCell>
                                <Table.HeaderCell>Vertriebslinie</Table.HeaderCell>
                                <Table.HeaderCell>Filiale</Table.HeaderCell>
                                <Table.HeaderCell>Format/Fläche</Table.HeaderCell>
                                <Table.HeaderCell>Menge/Reichweite</Table.HeaderCell>
                                <Table.HeaderCell>Einheit</Table.HeaderCell>
                                <Table.HeaderCell>Einzelpreis</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)}  name="sku" size="tiny" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:0, backgroundColor:'#fff'}}><Select style={{border:"0",height:20}} options={[
                                    {key: 1, text: "Online", value: "Online"},
                                    {key: 2, text: "Direct", value: "Direct"},
                                    {key: 3, text: "Store", value: "Store"},
                                    {key: 4, text: "Social Media", value: "Social Media"},
                                    {key: 5, text: "Event", value: "Event"},
                                    {key: 6, text: "Analytics", value: "Analytics"}
                                ]} onChange={(e,{name,value})=>this.changeFilter(name,value)} clearable text='' transparent name="channel" fluid /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="product_name" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="salesline" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="department" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="format" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="amount" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="unit" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{padding:5, backgroundColor:'#fff'}}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="single_price" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {this.state.data.data.length >0 && <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                                <Table.Row key={entry.id} style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/products/"+entry.id)}>
                                    <Table.Cell>{entry.sku}</Table.Cell>
                                    <Table.Cell>{entry.channel}</Table.Cell>
                                    <Table.Cell>{entry.product_name}</Table.Cell>
                                    <Table.Cell>{entry.salesline}</Table.Cell>
                                    <Table.Cell>{entry.department}</Table.Cell>
                                    <Table.Cell>{entry.format}</Table.Cell>
                                    <Table.Cell>{entry.amount}</Table.Cell>
                                    <Table.Cell>{entry.unit2}</Table.Cell>
                                    <Table.Cell><Currency quantity={entry.single_price} currency="EUR" /></Table.Cell>
                                </Table.Row>
                            ) }
                        </Table.Body>}
                    </Table>

                    {this.state.data.data.length ==0 &&<Segment placeholder attached="bottom">
                        <Header icon><Icon name='search' /> Es wurde kein Produkt gefunden </Header>
                    </Segment>}
                </Segment>
                <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2}
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
            </Segment>
        )
    }
}

export default withRouter(Products)