import React,{ Component } from "react";
import {Button, Segment, Rail, Input, Popup, Icon, Table, Pagination, Header, Select,Modal,Label } from "semantic-ui-react";
import SellerSingleCompact from "../Sellers/Single/compact";
import Moment from "react-moment";
import moment from "moment";
import client from "../../shared/feathers";


function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}
class ProductsImport extends Component{

    state={
      query:{
      },
      data:{
          total:1,
          limit:1,
          data:[]
      },
      customers:[],
      users:[],
      page: 1,
      loading:true,
      selected:[]
    }
    async componentDidMount(){
      document.title = 'Angebote';
      this.update()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("receipts").watch().find({query: {$sort:{id:-1},$and:[...Object.values(this.state.query)],$skip:(this.state.page * this.state.data.limit)-this.state.data.limit,...this.props.query }}).subscribe(async data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    changeFilter(name,value){
        this.setState({query:{...this.state.query,[name]:{[name]:{$like: "%"+value+"%" }}}}, ()=>this.update())
    }
    selectSingle(el){
      console.log(el)
      let selected = [...this.state.selected,el]
      this.setState({selected})
    }
    deleteSingle(id){
      this.state.selected.splice(id,1)
      this.setState({selected:[...this.state.selected]})
    }
    // TODO: Merfach auswahl
    render(){
        return ( 
            <Modal
                style={{width: "87%"}}
                size="large"
                centered={false} closeIcon
                open={this.props.open}
                onClose={() => this.props.setOpen(false)}
                onOpen={() => this.props.setOpen(true)}
            >
            <Modal.Header>Angebot importieren</Modal.Header>
                <Modal.Content>
                  <b>Ausgewählt: </b>
                  {this.state.selected.map((entry, id) => <Label>#{entry.id}<Icon onClick={id=>this.deleteSingle(id)} color="red" name='delete' /></Label>)}

                  <Rail  internal position='right'>
                    <Segment style={{borderBottom:0,textAlign:"right"}}>
                      <Button onClick={()=>{this.props.addProduct(this.state.selected.map(el=>([...JSON.parse(el.products)])).flat()); this.props.setOpen(false)}} primary>Importieren</Button>
                    </Segment>
                  </Rail>
                </Modal.Content>
                <Modal.Content   loading={this.state.loading} style={{padding:0}}>
                    <Table selectable celled >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>Ang. Nr.</Table.HeaderCell>
                                <Table.HeaderCell>Kunde</Table.HeaderCell>
                                <Table.HeaderCell>Deal</Table.HeaderCell>
                                <Table.HeaderCell>Produkte</Table.HeaderCell>
                                <Table.HeaderCell>Verkäufer</Table.HeaderCell>
                                <Table.HeaderCell >Datum</Table.HeaderCell>
                                <Table.HeaderCell >Ablauf</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                            <Table.HeaderCell collapsing style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="id" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 0}}>
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="deal_name" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 0}}>
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 0}}>
                                    <Select 
                                    noResultsMessage='Es wurden keine Mitarbeiter gefunden.' 
                                    search
                                    onSearchChange={(e,{searchQuery})=>this.search("users",{
                                        $or:[
                                            {firstname: {$like:"%"+searchQuery+"%"},},
                                            {lastname: {$like:"%"+searchQuery+"%"},},
                                            {id: {$like:"%"+searchQuery+"%"},}
                                        ]
                                    })}
                                    search={options=>this.state.users}
                                    options={this.state.users}  
                                    style={{border:"0",height:20}} 
                                    onChange={(e,{name,value})=>this.changeFilter(name,value)} 
                                    clearable
                                    transparent name="user_id" fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="created_at" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 5, backgroundColor: '#fff' }}><Input onChange={(e,{name,value})=>this.changeFilter(name,value)} name="until" icon="search" fluid transparent size="mini" /></Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: 0}}><Select style={{border:"0",height:20}} options={[{key:"open",value:"open",text:"Offen"},{key:"canceld",value:"canceld",text:"Gelöscht"},{key:"send",value:"send",text:"Gesendet"}]} onChange={(e,{name,value})=>this.changeFilter(name,value)} clearable text='' transparent name="status" fluid /></Table.HeaderCell>
                                
                            </Table.Row>
                        </Table.Header>
                        {this.state.data.data.length >0 && <Table.Body>
                            {this.state.data.data.map((entry, id) =>
                              <Table.Row key={entry.id} style={{cursor:"pointer"}}  onClick={()=>this.selectSingle({...entry})}>
                                <Table.Cell >#{pad(entry.id,4)}</Table.Cell>
                                <Table.Cell >
                                  <Popup
                                    trigger={<div>{(JSON.parse(entry.customer)?JSON.parse(entry.customer).name:"")}</div>}
                                    content={(JSON.parse(entry.customer)?JSON.parse(entry.customer).address:"") + ""}
                                    header={(JSON.parse(entry.customer)?JSON.parse(entry.customer).name:"")+ ""}
                                    basic
                                  />
                                </Table.Cell>
                                <Table.Cell ><small>({entry.deal_id})</small> {entry.deal_name} </Table.Cell>
                                <Table.Cell >{JSON.parse(entry.products).length}</Table.Cell>
                                <Table.Cell ><SellerSingleCompact seller_id={entry.user_id} /></Table.Cell>
                                <Table.Cell ><Moment format="DD.MM.YYYY" >{entry.created_at}</Moment></Table.Cell>
                                <Table.Cell ><Moment format="DD.MM.YYYY" >{entry.until}</Moment></Table.Cell>
                                <Table.Cell>
                                    {/* {entry.status=="open"&& <Label color="red" >Offen</Label>} */}
                                    {entry.status=="canceld"&& <Label color="red" basic>Gelöscht</Label>}
                                    {entry.status=="send"&& <Label color="green">Gesendet</Label>}
                                </Table.Cell>
                            </Table.Row>
                            ) }
                        </Table.Body>}
                    </Table>

                    {this.state.data.data.length ==0 &&<Segment placeholder attached="bottom">
                        <Header icon><Icon name='search' /> Es wurde kein Produkt gefunden </Header>
                    </Segment>}
                </Modal.Content >
                <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2}
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
            </Modal>
        )
    }
}

export default ProductsImport