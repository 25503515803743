import React,{ Component } from "react";
import { Tab } from "semantic-ui-react";
import CustomerEdit from "./change";


class Informations extends Component{
    state={
        loading: true,
        comments: []
    }

    async componentDidMount() {
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    render(){
        return (
            <Tab.Pane attached="bottom" style={{marginTop:0}}>
                    <CustomerEdit customer={this.props.customer} />
                
            </Tab.Pane>
        )
    }
}

export default Informations