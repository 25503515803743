import React,{ Component } from "react";
import { Segment, Button, Message, Table, Form, Icon,Checkbox,Input,TextArea,Popup,Divider,Grid,Search} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import Currency from "react-currency-formatter";
import client from '../../shared/feathers'
import AddProduct from "./addProduct";
import ImportProducts from './importProducts';
import moment from "moment";
import queryString from "query-string";

class ReceiptAdd extends Component{
    state={
      saving:false,
      error:null,
      addProduct:false,
      importProducts:false,
      newItem_amount:0,
      newItem_price:0,
      price:0,
      customer_id:null,
      taxfree:false,
      customers:[],
      customer:null,
      deal_id:null,
      supplier_id:null,
      deals:[],
      deal:null,
      suppliers:[],
      created_at: moment().format("DD-MM-YYYY"),
      until: moment().add(15,"days").format("DD-MM-YYYY"),
      products: []
    }
    async componentDidMount(){
        const parsed = queryString.parse(this.props.location.search);
        if(parsed.selectedIds){
          let deal = await client.service("deals").get(parsed.selectedIds)
          let customer = await client.service("customers").get(deal.org_id.value)
          this.setState({customer,customers:[{key:customer.id,value:customer.id,text:customer.name}],customer_id:customer.id,deal,deal_name:deal.title,deal_id:deal.id,deals:[{key:deal.id,value:deal.id,text:deal.title}]})
          await this.search("contacts",{term:"*",organization_id: deal.org_id.value})
        }

        await client.service("suppliers").find({query:{$limit:9000}}).then(data=>{
          this.setState({suppliers:data.data.map(el=>({
            key:el.id,
            value:el,
            text:el.supplier_name,
            description: el.supplier_id
          }))})
        })
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleCheckbox = (e,{name}) => this.setState({[name]: !this.state[name]})
    
    catToObj(obj){
        let result = {}
        for (let entry of obj){
            result[entry.id]=entry
        } return result
    }
    async search(field,query){
        let customers = await client.service(field).find({query})
        this.setState({[field+"_tab"]:this.catToObj(customers.map(el=>el.item)),[field]:customers.map((elm,index)=>({
            key:elm.item.id,
            value:elm.item.id,
            text:elm.item.name?elm.item.name:elm.item.title,
            description:elm.item.address?elm.item.address:(elm.item.supplier_id?elm.item.supplier_id:"")
        }))});
    }
    
    async handleChangeCustomer (e,{value}) {
      this.setState({
        customer_id: value,
        supplier_id: "",
        supplier_name: "",
        customer: this.state.customers_tab[value],
        holding:false,
        supplier_active:null
      })
      await client.service("suppliers").find({query:{$limit:9000}}).then(data=>{
        this.setState({suppliers:data.data.map(el=>({
          key:el.id,
          value:el,
          text:el.supplier_name,
          description: el.supplier_id
        }))})
      })
    }
    async handleChangeSupplier(e,{value}){
      this.setState({supplier_id:value.supplier_id,supplier_name:value.supplier_name,supplier_active:value})
    }
    async handleChangeDeal (e,{value}) {
        this.setState({
            deal_id: value,
            deal: this.state.deals_tab[value],
            deal_name: this.state.deals_tab[value].title
        })
    }
    async handleChangeContact (e,{value}) {
        if(value){
            this.setState({
                contact_id: value,
                contact: this.state.contacts_tab[value],
                contact_name: this.state.contacts_tab[value].name
            })
        }else{ 
            this.setState({
                
                contact_id: null,
                contact: null,
                contact_name: null,
            })
        }
    }
    removeProduct(index){
        this.setState({
            price:this.state.products.filter((el,i)=>i!=index).length>0?this.state.products.filter((el,i)=>i!=index).map((el)=>el.price*el.amount).reduce((acc,el)=>acc+el):0,
            products:Array.from(this.state.products).filter((el,i)=>i!=index)
        })
    }
    calulatePrices(prdkts){
        return prdkts.reduce((acc,el)=>(acc?acc:0) +((el.unit2=="TKP"?el.amount/1000*(el.single_price*(1+(el.surcharge/100))):el.amount*(el.single_price*(1+(el.surcharge/100))))*(1-(el.discount/100))),0)
    }
    async save(status="draft"){
        if(!this.state.customer_id){
            return this.setState({ error:"Bitte geben Sie einen Kunden an." })
        }
        if(!this.state.deal_id){
            return this.setState({ error:"Bitte geben Sie einen Deal an." })
        }
        if(this.state.products.length==0){
            return this.setState({ error:"Bitte fügen Sie Produkte zu dem Angebot hinzu." })
        }
        this.setState({saving:true,error:null})
        let user = await client.service("user").find()
        let {stockdeposit,timerange,taxfree,deal_name,created_at,until,customer_id,customer,products,deal_id,contact_name,contact_id,supplier_id,supplier_name} =this.state
        client.service("receipts").create({
          customer:JSON.stringify({...customer,next_activity:{},last_activity:{}}),
          stockdeposit,supplier_id,supplier_name,timerange,contact_name,contact_id,taxfree,deal_name,deal_id,status,user_id:user.id,created_at:moment(created_at,"DD-MM-YYYY").format("YYYY-MM-DD"),until:moment(until,"DD-MM-YYYY").format("YYYY-MM-DD"),customer_id,products:JSON.stringify(products)})
        .then(result=>this.props.history.push("/receipts/view/"+result.id)).catch((e)=>{
            console.log(e)
            this.setState({error:"Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.",saving:false})
        })
    }
    toggleProducts(val=true){
        this.setState({addProduct:val})
    }
    toggleOffers(val=true){
        this.setState({importProducts:val})
    }
    
    addProduct(prdk){
        let products = [...this.state.products, ...prdk]
        this.setState({products,addProduct:false})
    }
    changePrdc(index,data){
        this.state.products[index][data.name]=data.value
        this.setState({products:this.state.products})
    }
    render(){
        return (
                    <Segment basic className="extrasmall">
                        {!!this.state.addProduct && <AddProduct addProduct={(val)=>this.addProduct(val)} setOpen={(val)=>this.toggleProducts(val)} open={this.state.addProduct} />}
                        {!!this.state.importProducts && <ImportProducts addProduct={(val)=>this.addProduct(val)} setOpen={(val)=>this.toggleOffers(val)} open={this.state.importProducts} />}
                        <Form as="div" loading={this.state.saving}>
                            <Segment.Group >
                                <Segment.Group horizontal style={{backgroundColor:"#fff", borderRadius: ".28571429rem"}}>
                                    <Segment>
                                        <Form.Select required value={this.state.customer_id} name="customer_id" label="Kunde" placeholder='Kunden auswählen' fluid search noResultsMessage='Es wurde keine Kunden gefunden.' 
                                            onSearchChange={(e,{searchQuery})=>this.search("customers",{
                                               term: searchQuery
                                            })}
                                            options={this.state.customers}  
                                            onChange={this.handleChangeCustomer.bind(this)}
                                        />
                                        {this.state.customer_id && <Form.Select required value={this.state.deal_id} name="deal_id" label="Deal" placeholder='Deal auswählen' fluid search noResultsMessage='Es wurde keine Deals gefunden.' 
                                            onSearchChange={(e,{searchQuery})=>this.search("deals",{
                                               term: searchQuery,
                                               organization_id:this.state.customer_id
                                            })}
                                            options={this.state.deals}  
                                            onChange={this.handleChangeDeal.bind(this)}
                                        />}
                                        {this.state.customer_id && <Form.Select  value={this.state.contact_id} name="contact_id" label="Ansprechpartner" placeholder='Ansprechpartner auswählen' fluid search noResultsMessage='Es wurde keine Ansprechpartner gefunden.' 
                                            onSearchChange={(e,{searchQuery})=>this.search("contacts",{
                                               term: searchQuery,
                                               organization_id:this.state.customer_id
                                            })}
                                            clearable
                                            options={this.state.contacts}  
                                            onChange={this.handleChangeContact.bind(this)}
                                        />}
                                        <Form.Select 
                                          value={this.state.supplier_active} search
                                          name="contact_id" 
                                          label="Lieferant" 
                                          placeholder='Lieferant auswählen' 
                                          fluid 
                                          noResultsMessage='Es wurde keine Lieferanten gefunden.' 
                                          clearable
                                          options={this.state.suppliers}  
                                          onChange={this.handleChangeSupplier.bind(this)}
                                        />
                                    </Segment>
                                    <Segment>
                                        <Form.Input required label="Angebotsdatum" >
                                            <MaskedInput
                                                value={this.state.created_at}
                                                onChange={(e)=>this.handleChange(e,{name:"created_at",value:e.target.value})} 
                                                pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                                placeholder="DD-MM-YYYY" 
                                                mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                                guide={true} {...this.props} required  />
                                        </Form.Input>
                                        <Form.Input required label="Angebot gültig bis" >
                                            <MaskedInput
                                                value={this.state.until}
                                                onChange={(e)=>this.handleChange(e,{name:"until",value:e.target.value})} 
                                                pattern="[(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                                                placeholder="DD-MM-YYYY" 
                                                mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}  
                                                guide={true} {...this.props} required  />
                                        </Form.Input>
                                        <Form.Input label="Kampagnenzeitraum"
                                                value={this.state.timerange}
                                                onChange={(e)=>this.handleChange(e,{name:"timerange",value:e.target.value})} 
                                        />
                                        <Form.Input label="Warenabteilung / KST"
                                            value={this.state.stockdeposit}
                                            onChange={(e)=>this.handleChange(e,{name:"stockdeposit",value:e.target.value})} 
                                        />
                                        <Form.Field>
                                            <Checkbox onChange={()=>this.setState({taxfree:!this.state.taxfree})} label='Steuerfrei' />
                                        </Form.Field>
                                    </Segment>
                                </Segment.Group>
                                <Segment placeholder style={{minHeight:"auto"}}>
                                    <Grid columns={2} relaxed='very'>
                                      <Grid.Column>
                                        <Button onClick={()=>this.toggleProducts(true)} icon='add' fluid floating labeled button  className='icon' primary>Produkte hinzufügen</Button>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Button onClick={()=>this.toggleOffers(true)} icon='add' fluid floating labeled button  className='icon' primary>Angebot importieren</Button>
                                      </Grid.Column>
                                    </Grid>
                                    <Divider vertical>ODER</Divider>
                                </Segment>
                                <Form onSubmit={this.addProduct.bind(this)}>
                                <Table attached>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Kanal <br/><small>/ VL / Filiale</small></Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Produkt <br/><small>/ Format</small></Table.HeaderCell>
                                            <Table.HeaderCell >Beschreibung</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Menge</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Einheit</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Einzelpreis</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Einheit</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Aufschlag</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Einzelpreis<br/><small>nach Aufschlag</small></Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Gesamtpreis</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Rabatt</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>Gesamtpreis<br/><small>nach Rabatt</small></Table.HeaderCell>
                                            <Table.HeaderCell collapsing></Table.HeaderCell>
                                            <Table.HeaderCell collapsing></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.products.map((el,index)=>(
                                            <Table.Row key={index}>
                                                <Table.Cell verticalAlign="top">{el.sku}</Table.Cell>
                                                <Table.Cell verticalAlign="top"><b>{el.channel}</b><br/>{el.salesline}<br/>{el.department}</Table.Cell>
                                                <Table.Cell verticalAlign="top"><b>{el.product_name}</b> {el.format}</Table.Cell>
                                                <Table.Cell ><TextArea onChange={(e)=>this.changePrdc(index,{name:"description",value:e.target.value})} size="small" value={el.description}>{el.description}</TextArea></Table.Cell>
                                                <Table.Cell style={{width:"120px"}}><Input onChange={(e)=>this.changePrdc(index,{name:"amount",value:e.target.value})} fluid size="small" value={el.amount} type="number" /></Table.Cell>
                                                <Table.Cell>{el.unit}</Table.Cell>
                                                <Table.Cell style={{width:"120px"}}><Input onChange={(e)=>this.changePrdc(index,{name:"single_price",value:e.target.value})} size="small" value={el.single_price} type="number" /></Table.Cell>
                                                <Table.Cell>{el.unit2}</Table.Cell>
                                                <Table.Cell style={{width:"120px"}}><Input onChange={(e)=>this.changePrdc(index,{name:"surcharge",value:e.target.value})} style={{minWidth:80}} max={100} fluid labelPosition="right" label="%" type="number" size="small" value={el.surcharge} /></Table.Cell>
                                                <Table.Cell><Currency quantity={el.single_price*(1+(el.surcharge/100))} currency="EUR" /></Table.Cell>
                                                <Table.Cell><Currency quantity={el.unit2=="TKP"?el.amount/1000*(el.single_price*(1+(el.surcharge/100))):el.amount*(el.single_price*(1+(el.surcharge/100)))} currency="EUR" /></Table.Cell>
                                                <Table.Cell style={{width:"120px"}}><Input onChange={(e)=>this.changePrdc(index,{name:"discount",value:e.target.value})} style={{minWidth:80}} max={100} fluid labelPosition="right" label="%" type="number" size="small" value={el.discount} /></Table.Cell>
                                                <Table.Cell><Currency quantity={(el.unit2=="TKP"?el.amount/1000*(el.single_price*(1+(el.surcharge/100))):el.amount*(el.single_price*(1+(el.surcharge/100))))*(1-(el.discount/100))} currency="EUR" /></Table.Cell>
                                                <Table.Cell>{!!el.intern && <Popup content={el.intern} trigger={<Button as="a" href="#" icon='info' />} />}</Table.Cell>
                                                <Table.Cell><Button as="a" onClick={()=>this.removeProduct(index)} icon negative><Icon name="trash"  /></Button></Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                </Form>
                                <Segment.Group attached horizontal style={{backgroundColor:"#fff", borderRadius: "0 0 .28571429rem .28571429rem"}}>
                                    <Segment></Segment>
                                    <Segment>
                                        <Table definition>
                                            {!this.state.taxfree && <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell textAlign="right">Gesamt Netto</Table.Cell>
                                                    <Table.Cell textAlign="right"><Currency quantity={Number(this.calulatePrices(this.state.products))} currency="EUR" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell textAlign="right">zzgl. MWST 19%</Table.Cell>
                                                    <Table.Cell textAlign="right"><Currency quantity={this.calulatePrices(this.state.products)*0.19} currency="EUR" /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell textAlign="right">Gesamt inkl. MWST</Table.Cell>
                                                    <Table.Cell textAlign="right"><Currency quantity={this.calulatePrices(this.state.products)*1.19} currency="EUR" /></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>}
                                            {!!this.state.taxfree && <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell textAlign="right">Gesamt</Table.Cell>
                                                    <Table.Cell textAlign="right"><Currency quantity={Number(this.calulatePrices(this.state.products))} currency="EUR" /></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>}
                                        </Table>
                                    </Segment>
                                </Segment.Group>
                            </Segment.Group>
                            {this.state.error && <Message negative >{this.state.error}</Message>}
                                    
                            <div style={{textAlign:"right"}}>
                                <Button.Group >
                                    {/*<Form.Button onClick={()=>this.save("draft")} style={{marginRight:10}} secondary>Speichern als Entwurf</Form.Button>*/}
                                    <Form.Button onClick={()=>this.save("open")} primary>Angebot erstellen</Form.Button>
                                </Button.Group>
                            </div>
                        </Form>
                    </Segment>)
        
    }
}

export default withRouter(ReceiptAdd)