import React, { Component } from "react";
import { Segment, Modal, Button, Form, Header, Message,Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { HuePicker } from 'react-color';
import client from '../../shared/feathers'

var strongRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
class SellersNew extends Component {
    state = {
      saving:false,
      error:false,
      customers:[],
      customer_id:null,
      open:false
    }
    
    async search(field,query){
      let customers = await client.service(field).find({query})
      this.setState({[field+"_tab"]:this.catToObj(customers.map(el=>el.item)),[field]:customers.map((elm,index)=>({
          key:elm.item.id,
          value:elm.item.id,
          text:elm.item.name?elm.item.name:elm.item.title,
          description:elm.item.address?elm.item.address:""
      }))});
    }
  
    async handleChangeCustomer (e,{value}) {
      this.setState({
          customer_id: value,
          customer: this.state.customers_tab[value],
          holding:false
      })
    }
    catToObj(obj){
        let result = {}
        for (let entry of obj){
            result[entry.id]=entry
        } return result
    }
    save(){
        this.setState({saving:true})
        let {supplier_id,supplier_name,customer_id} = this.state
        client.service("suppliers").create({supplier_id,supplier_name,customer_id,customer_name:this.state.customer.name}).then((result)=>{
          this.setState({
            open:false, 
            error:false,
            supplier_id:"",
            supplier_name:"",
            saving:false,
          })
        }).catch((e)=>{
            console.log(e)
            this.setState({saving:false,error:true})
        })
    }
    handleChange = (e, { name, value }) =>{
        this.setState({ [name]: value })
    }
    render() {
        return (
            <Modal 
            onClose={() => this.setState({open:false})}
            onOpen={() => this.setState({open:true})} open={this.state.open}
            closeIcon closeOnDimmerClick={false} size='small' trigger={this.props.trigger} >
                <Modal.Header>Neuer Lieferant</Modal.Header>
                <Modal.Content scrolling>
                    <Form loading={this.state.saving} id="userCreateForm" onSubmit={this.save.bind(this)}>
                        <Segment.Group >
                            <Header attached="top" >Allgemeine Informationen</Header>
                            <Segment attached="bottom" color="green">

                                <Form.Select required value={this.state.customer_id} name="customer_id" label="Kunde" placeholder='Kunden auswählen' fluid search noResultsMessage='Es wurde keine Kunden gefunden.' 
                                  onSearchChange={(e,{searchQuery})=>this.search("customers",{
                                      term: searchQuery
                                  })}
                                  options={this.state.customers}  
                                  onChange={this.handleChangeCustomer.bind(this)}
                                />
                                <Form.Field>
                                    <label>Lieferanten Nummer</label>
                                    <Input name="supplier_id" onChange={this.handleChange.bind(this)}  />
                                </Form.Field>
                                <Form.Field>
                                    <label>Lieferanten Name</label>
                                    <Input name="supplier_name" onChange={this.handleChange.bind(this)}  />
                                </Form.Field>
                            </Segment>
                        </Segment.Group>
                        <Segment style={{ textAlign: "center" }}>
                            {this.state.error && <Message negative >Es ist ein Fehler beim speichern aufgetreten. Bitte überprüfen Sie alle Felder oder kontaktieren Sie den Support.</Message>}
                            <Button
                                disabled={
                                    !this.state.supplier_name || 
                                    !this.state.supplier_id ||
                                    !this.state.customer_id 
                                }
                                type="submit"
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content="Hinzufügen"
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
            </Modal >
        )
    }
}

export default withRouter(SellersNew)