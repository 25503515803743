import React, {Component} from 'react'
import {  Sidebar, Menu, Icon, Dropdown, Button, Header } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import client from '../shared/feathers'
let Groups = {administrator:"Administrator",manager:"Manager",salesman:"Verkäufer",accountant:"Buchhalter",mechanic:"Mechaniker",carcreator:"Fahrzeugerfasser"}

class MenuDasboard extends Component{
    state={
        loading:true,
        open:false
    }
    async componentDidMount(){
        let user = await client.service("user").find()
        this.setState({user,loading:false})
    }
    render(){
        if(this.state.loading){
            return <Sidebar inverted style={{width:100, zIndex:0}} direction="left"  icon='labeled' animation="overlay" icon='labeled' visible loading />
        }
        return (
        <div >
            <Menu fluid fixed="top" inverted visible style={{backgroundColor: '#0b385b'}}>

                    <Menu.Item style={{paddingTop:0,paddingBottom:0}}>
                        <img src='/logo.png'  />
                    </Menu.Item>    
                    {/* <Menu.Item 
                        id="menu-dashboard" 
                        onClick={()=>this.props.history.push("/dashboard")} 
                        link  
                        active={this.props.history.location.pathname.includes("/dashboard") || this.props.history.location.pathname == "/"} >
                    <Icon name="dashboard" />Dashboard
                    </Menu.Item> */}
                    {["administrator","manager"].includes(this.state.user.permissions) && <Menu.Item id="menu-sellers" onClick={()=>this.props.history.push("/sellers")} link active={this.props.history.location.pathname.includes("/sellers") }><Icon name="user" />Mitarbeiter</Menu.Item>}
                    {/* {["administrator","manager"].includes(this.state.user.permissions) && <Menu.Item id="menu-categories" onClick={()=>this.props.history.push("/categories")} link active={this.props.history.location.pathname.includes("/categories") }><Icon name="folder" />Kategorien</Menu.Item>} */}
                    {["administrator","manager"].includes(this.state.user.permissions) && <Menu.Item id="menu-products" onClick={()=>this.props.history.push("/products")} link active={this.props.history.location.pathname.includes("/products") }><Icon name="tags" />Produkte</Menu.Item>}
                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-wkz" onClick={()=>this.props.history.push("/wkz")} link active={this.props.history.location.pathname.includes("/wkz") }><Icon name="truck" /> WKZ-Lieferanten</Menu.Item>}
                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) && <Menu.Item id="menu-receipts" onClick={()=>this.props.history.push("/receipts")} link active={this.props.history.location.pathname.includes("/receipts")}><Icon name="paste" />Angebote</Menu.Item>}
                    <Menu.Menu position='right'>
                      <Dropdown item text={this.state.user.firstname+" "+this.state.user.lastname}>
                          <Dropdown.Menu>
                              <Dropdown.Item onClick={()=>{client.logout();this.props.history.push("/");}}><Icon name="shutdown" /> Abmelden</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Menu>
            </Menu>
        </div>)
    }
} 

export default withRouter(MenuDasboard)