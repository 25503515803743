import React, { Component } from "react";
import { Segment, Input, Table, Pagination, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Currency from "react-currency-formatter";
import client from "../../shared/feathers";

let Groups = {
  administrator: "Administrator",
  manager: "Manager",
  salesman: "Verkäufer",
  accountant: "Buchhalter",
  mechanic: "Mechaniker",
  carcreator: "Fahrzeugerfasser",
};
class WkzList extends Component {
  state = {
    query: {},
    data: {
      total: 1,
      limit: 1,
      data: [],
    },
    dataClosed: {
      total: 1,
      limit: 1,
      data: [],
    },
    quotaWarning: false,
    company: { maxUsers: 100 },
    page: 1,
    loading: true,
  };
  async componentDidMount() {
    document.title = "Mitarbeiter";
    this.update();
  }

  changePage(event, data) {
    this.setState({ loading: true, page: data.activePage }, () =>
      this.update()
    );
  }
  update() {
    this.setState({ loading: true });
    if (this.reactive) {
      this.reactive.unsubscribe();
    }
    if (this.reactiveClosed) {
      this.reactiveClosed.unsubscribe();
    }
    this.reactive = client
      .service("suppliers")
      .watch()
      .find({
        query: {
          $limit: 6,
          $or: [...Object.values(this.state.query)],
          $skip:
            this.state.page * this.state.data.limit - this.state.data.limit,
          ...this.props.query,
        },
      })
      .subscribe(async (data) => this.setState({ data, loading: false }));
    this.reactiveClosed = client
      .service("suppliers")
      .watch()
      .find({
        query: {
          $limit: 6,
          $or: [...Object.values(this.state.query)],
          $skip:
            this.state.page * this.state.data.limit - this.state.data.limit,
          ...this.props.query,
        },
      })
      .subscribe(async (dataClosed) =>
        this.setState({ dataClosed, loading: false })
      );
  }
  componentWillUnmount() {
    if (this.reactive) {
      this.reactive.unsubscribe();
    }
    if (this.reactiveClosed) {
      this.reactiveClosed.unsubscribe();
    }
  }
  changeFilter(name, value) {
    this.setState(
      {
        query: {
          ...this.state.query,
          [name]: { [name]: { $like: "%" + value + "%" } },
        },
      },
      () => this.update()
    );
  }
  async onDelete(id) {
    await client.service("suppliers").remove(id);
  }
  render() {
    return (
      <div>
        <Segment.Group style={{ padding: 0 }}>
          <Table selectable attached="top" celled size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                <Table.HeaderCell>Kunden Nr.</Table.HeaderCell>
                <Table.HeaderCell>Kunde</Table.HeaderCell>
                <Table.HeaderCell>Lieferanten Nr.</Table.HeaderCell>
                <Table.HeaderCell>Lieferanten Name</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  collapsing
                  style={{ padding: 5, backgroundColor: "#fff" }}
                >
                  <Input
                    onChange={(e, { name, value }) =>
                      this.changeFilter(name, value)
                    }
                    name="id"
                    fluid
                    transparent
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ padding: 5, backgroundColor: "#fff" }}
                >
                  <Input
                    onChange={(e, { name, value }) =>
                      this.changeFilter(name, value)
                    }
                    name="customer_id"
                    icon="search"
                    fluid
                    transparent
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ padding: 5, backgroundColor: "#fff" }}
                >
                  <Input
                    onChange={(e, { name, value }) =>
                      this.changeFilter(name, value)
                    }
                    name="customer_name"
                    icon="search"
                    fluid
                    transparent
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ padding: 5, backgroundColor: "#fff" }}
                >
                  <Input
                    onChange={(e, { name, value }) =>
                      this.changeFilter(name, value)
                    }
                    name="supplier_id"
                    icon="search"
                    fluid
                    transparent
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ padding: 5, backgroundColor: "#fff" }}
                >
                  <Input
                    onChange={(e, { name, value }) =>
                      this.changeFilter(name, value)
                    }
                    name="supplier_name"
                    icon="search"
                    fluid
                    transparent
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ padding: 5, backgroundColor: "#fff" }}
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data.data.map((entry, id) => (
                <Table.Row key={entry.id}>
                  <Table.Cell>{entry.id}</Table.Cell>
                  <Table.Cell>{entry.customer_id}</Table.Cell>
                  <Table.Cell>{entry.customer_name}</Table.Cell>
                  <Table.Cell>{entry.supplier_id}</Table.Cell>
                  <Table.Cell>{entry.supplier_name}</Table.Cell>
                  <Table.Cell>
                    <Button
                      size="tiny"
                      icon="trash"
                      onClick={() => this.onDelete(entry.id).then(() => {})}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pagination
            onPageChange={this.changePage.bind(this)}
            attached="bottom"
            boundaryRange={2}
            siblingRange={2}
            fluid
            defaultActivePage={this.state.page}
            totalPages={Math.ceil(
              this.state.data.total / this.state.data.limit
            )}
          />
        </Segment.Group>
      </div>
    );
  }
}

export default withRouter(WkzList);
