import React, { Component } from "react";
import { Grid, Segment, Loader, Message, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import client from "../shared/feathers";

class Forgot extends Component {
  state = {
    err: false,
    loading: true,
  };
  componentDidMount() {
    client
      .service("authManagement")
      .create({
        action: "verifySignupLong",
        value: this.props.match.params.token,
        notifierOptions: { preferredComm: "email" }, // options passed to options.notifier, e.g. {preferredComm: 'cellphone'}
      })
      .then(() => {
        this.props.history.push("/Authentification/Verify-Successfull");
      })
      .catch((e) => {
        console.log(e);
        this.setState({ err: true, loading: false });
      });
  }
  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column textAlign="left" style={{ maxWidth: 760 }}>
          <Segment style={{ overflow: "hidden", height: 326 }}>
            <Loader active={this.state.loading}>
              Verifiziert Registrierung
            </Loader>
            {this.state.err && (
              <Message negative>
                <Message.Header style={{ marginBottom: 10 }}>
                  Verifizierung fehlgeschlagen.
                </Message.Header>
                <span>
                  Der angegebene "Token" ist nicht korrekt oder wurde bereits
                  eingelöst.
                  <br /> Wenn Sie sich nicht anmelden können kontaktieren Sie
                  bitte den Support.
                </span>
              </Message>
            )}
            {this.state.err && (
              <div>
                <Button onClick={() => this.props.history.push("/")}>
                  Zurück zur Anmeldung
                </Button>
              </div>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(Forgot);
