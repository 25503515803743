import React, { Component } from "react";
import { Segment, Header, Tab, Menu, Icon, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Informations from "./informations";
import client from "../../../shared/feathers";

class ProductsSingle extends Component {
    state={
        loading:true,
        notFound: false,
        customer: null
    }
    componentDidMount() {
        this.reactive = client.service("products").watch().get( this.props.match.params.id).subscribe(async (data)=>this.setState({customer:data,loading:false}),()=>this.setState({loading:false, notFound: true}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    async delete(){
        await client.service("products").remove(this.props.match.params.id)
        this.props.history.push("/products")
    }
    render() {
        if(this.state.loading){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder color="red"> <Header icon><Icon name='search' /> Kunde wurde nicht gefunden.</Header> </Segment></Segment>
        }
        let {customer} = this.state

        const panes = [
            { menuItem: (<Menu.Item ><Icon name="info circle" /> Informationen</Menu.Item>), render: () => <Informations customer={customer} /> },
        ]

        return (
            <Segment basic >

                <Segment.Group>

                    {/*<Label attached="top" color="green">
                        Der nächste <b>Verkaufs</b> Termin ist am: <b>10.12.2019 15:00</b>
                        <Label.Detail>Termin öffnen </Label.Detail>
                    </Label>*/}
                    <Header attached="top" style={{ position: "relative" }}>
                        {customer.product_name} {customer.firstname} {customer.lastname}
                        <Header.Subheader>#{customer.sku} - {customer.channel} </Header.Subheader>
                        <div style={{ position: "absolute", right: 10, top: 10 }}>
                            <Button color="red" onClick={()=>this.delete()}>Löschen </Button>
                        </div>
                    </Header>
                    <Tab menu={{ widths: 6, secondary: true, pointing: true, style: { marginBottom: 0 } }} panes={panes} />
                </Segment.Group>
            </Segment>
        )
    }
}

export default withRouter(ProductsSingle)