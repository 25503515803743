import React, { Component } from "react";
import { Segment, Menu, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ReceiptsList from './list'

class Receipts extends Component {
    render() {
        return (
            <Segment basic >
                {!this.props.hideMenu && <Menu>
                    <Menu.Item><b>Angebote</b></Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item onClick={()=>this.props.history.push("/receipts/add")}><Icon name="plus square" /> Hinzufügen</Menu.Item>
                    </Menu.Menu>
                </Menu>}
                <ReceiptsList />
            </Segment>
        )
    }
}

export default withRouter(Receipts)