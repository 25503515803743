import React, { Component } from 'react';
import { Segment, Menu, Icon, Loader, Container } from 'semantic-ui-react';
import chance from 'chance';
import WksList from './list';
import WksNew from './new';
import client from '../../shared/feathers';

const IChance = new chance();
class Sellers extends Component {
  async componentDidMount () {
  }

  render () {
    return (
      <Container>
        <Segment basic>
          {!this.props.hideMenu && <Menu>
            <Menu.Item><b>WKZ Lieferanten</b></Menu.Item>
            <Menu.Menu position='right'>
              <WksNew trigger={<Menu.Item><Icon name='plus square' /> Hinzufügen</Menu.Item>} />
            </Menu.Menu>
          </Menu>}
          <WksList query={{}} />
        </Segment>
      </Container>
    );
  }
}

export default Sellers
;
