import React, {Component} from 'react'
import { Loader } from 'semantic-ui-react'
import {BrowserRouter, Route } from 'react-router-dom'
import client from '../shared/feathers'

import moment from 'moment';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Menu from './Menu';
import Customers from './Customers'
import CustomersSingle from './Customers/Single/'
import Sellers from './Sellers'
import Wkz from './Wkz'
import AuthPipedrive from './Sellers/AuthPipedrive'
import SellerSingle from './Sellers/Single/'
import Receipts from './Receipts'
import ReceiptSingle from './Receipts/Single'
import ReceiptAdd from "./Receipts/add";
import ReceiptEdit from "./Receipts/edit";
import Context from "./context";

class Dashboard extends Component{
    state={
        open:false,
        create:false,
        event_id:0,
        hover:true,
        loading: true,
        openEvent:this.openEvent.bind(this),
        closeEvent:this.closeEvent.bind(this),
        start:"",end:""
    }
    openEvent(event_id,start,end){
        if(event_id==0){
            this.setState({open:true, create:true,start,end,event_id:0})
        }else{
            this.setState({open:true,create:false,event_id:event_id.id})
        }
    }
    closeEvent(callback){
        this.setState({open:false, create:false,start:moment(),end:moment()},callback)
    }
    async componentDidMount(){
        document.title = 'Dashboard';
        let user = await client.service("user").find()
        this.setState({user,loading:false})
    }
    render(){
        return (
            <BrowserRouter >
                <Context.Provider value={this.state}>
                            <Loader active={this.state.loading} />

                            <Menu />
                            {!this.state.loading &&<div style={{marginTop:50}}>
                                {/*<Tutorial company={this.state.company} user={this.state.user}/>*/}
                                <div 
                                    onMouseEnter={()=>this.setState({hover:true})}
                                    className="inverted" >
                                    <Route path="/" exact component={()=><Receipts />} />
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/products" exact component={Customers} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/products/:id" exact component={CustomersSingle} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/sellers" exact component={Sellers} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/sellers/:id" exact component={SellerSingle} />}
                                    {["administrator","manager"].includes(this.state.user.permissions) && <Route path="/wkz" exact component={Wkz} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts" exact component={Receipts} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts/add" exact component={ReceiptAdd} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts/view/:id" exact component={ReceiptSingle} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/receipts/add/:id" exact component={ReceiptEdit} />}
                                    {["administrator","manager","salesman","mechanic","accountant"].includes(this.state.user.permissions) &&<Route path="/callback" exact component={AuthPipedrive} />}
                                    {/* {["administrator","manager"].includes(this.state.user.permissions) &&<Route path="/categories" component={Categories} />} */}
                                    
                                </div>
                            </div>}
                </Context.Provider>
            </BrowserRouter>
        )
    }
} 

export default Dashboard