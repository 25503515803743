import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import reactive from 'feathers-reactive'

//import memory from 'feathers-memory';

//app.use('/register', memory());
//location.hostname == "localhost"?"http://localhost:50006":
const socket = io('https://signa-api.d2.tecoyo.com');
const client = feathers();


client.configure(feathers.socketio(socket,{
  timeout: 20000
}));
client.configure(reactive({idField:"id"}));
client.configure(feathers.authentication({
  storage: window.localStorage
}));
window.client = client
export default client



// api.socket.on('rate-limit', (error) => ...)
//api.socket.on('disconnect', () => ...)